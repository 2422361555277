import React, { useState, useEffect } from 'react';
import './CustomChecklist.css';

const CustomChecklist = ({ roomId }) => {
  const [checklistItems, setChecklistItems] = useState([]);
  const [newItem, setNewItem] = useState('');

  useEffect(() => {
    // Simulate fetching checklist items from an API
    const fetchChecklist = async () => {
      const mockData = [
        { id: 1, text: 'Ensure all test cases are executed', completed: false },
        { id: 2, text: 'Verify database migrations', completed: true },
      ];
      setChecklistItems(mockData);
    };

    fetchChecklist();
  }, [roomId]);

  const handleAddItem = () => {
    if (!newItem.trim()) return;
    const newChecklistItem = {
      id: checklistItems.length + 1,
      text: newItem,
      completed: false,
    };
    setChecklistItems([...checklistItems, newChecklistItem]);
    setNewItem('');
  };

  const toggleCompletion = (id) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item
      )
    );
  };

  return (
    <div className="custom-checklist">
      <h3>Custom Checklist</h3>
      <div className="checklist-form">
        <input
          type="text"
          placeholder="Add a new checklist item"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
        />
        <button onClick={handleAddItem}>Add</button>
      </div>
      <ul className="checklist-items">
        {checklistItems.map((item) => (
          <li key={item.id} className={item.completed ? 'completed' : ''}>
            <label>
              <input
                type="checkbox"
                checked={item.completed}
                onChange={() => toggleCompletion(item.id)}
              />
              {item.text}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomChecklist;
