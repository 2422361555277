import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CountdownTimer from '../components/CountdownTimer';
import { Bar } from 'react-chartjs-2';
import Confetti from 'react-confetti';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import config from '../config';
import './RoomView.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function RoomView() {
  const { id } = useParams();
  const location = useLocation();
  const [room, setRoom] = useState(null);
  const [columns, setColumns] = useState([]);
  const [feedbackItems, setFeedbackItems] = useState([]);
  const [feedbackInputs, setFeedbackInputs] = useState({});
  const [showConfetti, setShowConfetti] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [animateFeedback, setAnimateFeedback] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const shareableLink = `${window.location.origin}${location.pathname}`;

  const retroStyles = {
    'Mad-Sad-Glad': [
      { id: 'mad', title: 'Mad' },
      { id: 'sad', title: 'Sad' },
      { id: 'glad', title: 'Glad' },
    ],
    'Start-Stop-Continue': [
      { id: 'start', title: 'Start' },
      { id: 'stop', title: 'Stop' },
      { id: 'continue', title: 'Continue' },
    ],
    '4Ls': [
      { id: 'liked', title: 'Liked' },
      { id: 'learned', title: 'Learned' },
      { id: 'lacked', title: 'Lacked' },
      { id: 'longed_for', title: 'Longed For' },
    ],
    Custom: [],
  };

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        setLoading(true);

        // Fetch room details
        const roomResponse = await axios.get(`${config.apiBaseUrl}/retros/access/${id}`);
        setRoom(roomResponse.data);

        const selectedColumns =
          retroStyles[roomResponse.data.retroStyle] || retroStyles['Mad-Sad-Glad'];
        setColumns(selectedColumns);

        // Fetch feedback items or retro template
        const feedbackResponse = await axios.get(
          `${config.apiBaseUrl}/retros/access/${id}/feedback`
        );
        const feedbackData = feedbackResponse.data;

        // If feedbackData is an array, use it. If it's a retro template, initialize feedback inputs.
        if (Array.isArray(feedbackData)) {
          setFeedbackItems(feedbackData);
        } else {
          setFeedbackItems([]); // Ensure clean slate
          setFeedbackInputs(
            feedbackData.reduce((acc, column) => {
              acc[column.id] = ['']; // Initialize empty inputs for each column
              return acc;
            }, {})
          );
        }

        setErrorMessage('');
      } catch (error) {
        setRoom(null);
        setErrorMessage(
          error.response?.data?.message || 'Room not found or you do not have permission to access it.'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchRoomData();
  }, [id]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedFeedback = Array.from(feedbackItems);
    const [movedItem] = updatedFeedback.splice(result.source.index, 1);
    updatedFeedback.splice(result.destination.index, 0, movedItem);
    setFeedbackItems(updatedFeedback);

    axios
      .post(`${config.apiBaseUrl}/retros/access/${id}/feedback/reorder`, {
        feedbackItems: updatedFeedback,
      })
      .catch((error) => console.error('Failed to reorder feedback:', error));
  };

  const handleInputChange = (columnId, index, value) => {
    setFeedbackInputs((prevInputs) => ({
      ...prevInputs,
      [columnId]: prevInputs[columnId]?.map((input, i) =>
        i === index ? value : input
      ) || [],
    }));
  };

  const addField = (columnId) => {
    setFeedbackInputs((prevInputs) => ({
      ...prevInputs,
      [columnId]: [...(prevInputs[columnId] || []), ''],
    }));
  };

  const submitFeedback = async () => {
    if (!room) return;

    const feedbackPromises = Object.entries(feedbackInputs).flatMap(
      ([columnId, contents]) =>
        contents
          .filter((content) => content.trim())
          .map((content) =>
            axios.post(`${config.apiBaseUrl}/retros/access/${id}/feedback`, {
              content,
              column: columnId,
              isAnonymous: room.isAnonymous,
            })
          )
    );

    try {
      await Promise.all(feedbackPromises);
      const updatedFeedbackItems = await axios.get(
        `${config.apiBaseUrl}/retros/access/${id}/feedback`
      );
      setFeedbackItems(updatedFeedbackItems.data);
      setShowSummary(true);
      setAnimateFeedback(true);

      if (feedbackInputs.kudos?.some((kudo) => kudo.trim())) {
        setShowConfetti(true);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setTimeout(() => setAnimateFeedback(false), 300);
    }
  };

  const feedbackSummaryData = {
    labels: columns.map((col) => col.title),
    datasets: [
      {
        label: 'Feedback Count',
        data: columns.map(
          (col) => feedbackItems.filter((item) => item.column === col.id).length
        ),
        backgroundColor: 'rgba(75,192,192,0.6)',
      },
    ],
  };

  if (loading) {
    return (
      <div className="container">
        <h2>Loading Room...</h2>
      </div>
    );
  }

  if (!room) {
    return (
      <div className="container">
        <h2>{errorMessage || 'Room not available'}</h2>
      </div>
    );
  }

  return (
    <div className="container">
      <CountdownTimer />
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={300}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
      <div className="room-header">Room: {room.name}</div>
      <div className="shareable-link">
        <p>Share this room link with your team:</p>
        <input type="text" value={shareableLink} readOnly />
        <button onClick={() => navigator.clipboard.writeText(shareableLink)}>
          Copy Link
        </button>
      </div>

      {!showSummary ? (
        <>
          <div className="column-container">
            <DragDropContext onDragEnd={handleDragEnd}>
              {columns.map((column) => (
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="column"
                    >
                      <h2>{column.title}</h2>
                      <div className="review-section">
                        {feedbackItems
                          .filter((item) => item.column === column.id)
                          .map((item, index) => (
                            <div
                              key={item._id}
                              className={`feedback-item ${
                                animateFeedback ? 'pop-in' : ''
                              }`}
                            >
                              <p>{item.content}</p>
                            </div>
                          ))}
                      </div>
                      {feedbackInputs[column.id]?.map((input, index) => (
                        <div key={index} className="feedback-input">
                          <textarea
                            placeholder={`Add feedback for ${column.title}...`}
                            value={input}
                            onChange={(e) =>
                              handleInputChange(column.id, index, e.target.value)
                            }
                          />
                        </div>
                      ))}
                      <button onClick={() => addField(column.id)}>
                        + Add Field
                      </button>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
          <div className="submit-button-container">
            <button onClick={submitFeedback}>Submit Feedback</button>
          </div>
        </>
      ) : (
        <div className="summary-section">
          <h2>Retro Summary</h2>
          <Bar data={feedbackSummaryData} />
          <button onClick={() => setShowSummary(false)}>
            Back to Feedback
          </button>
        </div>
      )}
    </div>
  );
}

export default RoomView;
