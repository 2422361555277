const trackEvent = (eventName, eventParams) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', eventName, eventParams);
      console.log(`Event tracked: ${eventName}`, eventParams); // Debug log
    } else {
      console.warn('Google Analytics is not initialized.');
    }
  };
  
  export default trackEvent;
  