import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      try {
        window.gtag('event', 'page_view', {
          page_path: location.pathname,
          page_title: document.title,
        });
        console.log(`Page view logged for: ${location.pathname}`);
      } catch (error) {
        console.error('Error in usePageTracking:', error);
      }
    }
  }, [location]);
};

export default usePageTracking;
