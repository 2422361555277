import React, { useState } from 'react';
import './CreateRoomModal.css';

const CreateRoomModal = ({ onClose, onRoomCreate }) => {
  const [roomName, setRoomName] = useState('');
  const [description, setDescription] = useState('');
  const [targetReleaseDate, setTargetReleaseDate] = useState('');
  const [error, setError] = useState('');

  const handleCreateRoom = async () => {
    if (!roomName || !targetReleaseDate) {
      setError('Room name and release date are required');
      return;
    }

    try {
      const response = await fetch('/api/boards/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: roomName,
          description,
          targetReleaseDate,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create room');
      }

      const newRoom = await response.json();
      onRoomCreate(newRoom);
      onClose();
    } catch (error) {
      console.error('Error creating room:', error);
      setError('Error creating room. Please try again.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Create New Room</h2>
        {error && <p className="error-message">{error}</p>}
        <label>
          Room Name:
          <input
            type="text"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            placeholder="Enter room name"
          />
        </label>
        <label>
          Description (Optional):
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter room description"
          ></textarea>
        </label>
        <label>
          Target Release Date:
          <input
            type="date"
            value={targetReleaseDate}
            onChange={(e) => setTargetReleaseDate(e.target.value)}
          />
        </label>
        <div className="modal-actions">
          <button className="create-button" onClick={handleCreateRoom}>
            Create Room
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRoomModal;
