import React, { useState, useEffect } from 'react';
import './TimelineView.css';

const TimelineView = ({ roomId }) => {
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState('All');

  useEffect(() => {
    // Simulate fetching timeline events
    const mockEvents = [
      { id: 1, title: 'Kickoff Meeting', date: '2024-11-01', type: 'Milestone' },
      { id: 2, title: 'Code Freeze', date: '2024-11-10', type: 'Deadline' },
      { id: 3, title: 'Release Deployment', date: '2024-11-15', type: 'Milestone' },
      { id: 4, title: 'Bug Fixes', date: '2024-11-16', type: 'Task' },
    ];
    setEvents(mockEvents);
  }, [roomId]);

  const filteredEvents = filter === 'All' ? events : events.filter((e) => e.type === filter);

  return (
    <div className="timeline-view">
      <h2>Timeline for Room {roomId}</h2>

      <div className="filter-controls">
        <label>Filter by Type:</label>
        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="All">All</option>
          <option value="Milestone">Milestone</option>
          <option value="Deadline">Deadline</option>
          <option value="Task">Task</option>
        </select>
      </div>

      <ul className="timeline-list">
        {filteredEvents.map((event) => (
          <li key={event.id} className={`timeline-event ${event.type.toLowerCase()}`}>
            <p>
              <strong>{event.title}</strong> - {new Date(event.date).toLocaleDateString()}
            </p>
            <span className="event-type">{event.type}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TimelineView;
