import React from 'react';

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: '#121212', // Dark Gray Background
        color: '#ffffff', // White Text
        textAlign: 'center',
        padding: '10px',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        fontSize: '0.9rem',
        zIndex: 1000, // Ensure it stays on top
      }}
    >
      ©Agile AgileHubTools 2024 - Accessibility and responsiveness are not built yet.
    </footer>
  );
}

export default Footer;
