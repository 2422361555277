import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReleaseReadinessTrackerPage.css';
import CreateRoomModal from '../components/CreateRoomModal';
import RoomDashboard from '../components/RoomDashboard'; // Assuming RoomDashboard is the correct component

const ReleaseReadinessTrackerPage = () => {
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMockRooms = () => {
      // Mock data for testing
      const mockRooms = [
        {
          roomId: '1',
          name: 'Release 1',
          targetReleaseDate: '2024-12-01',
          readiness: 'Ready',
        },
        {
          roomId: '2',
          name: 'Release 2',
          targetReleaseDate: '2024-12-15',
          readiness: 'Not Ready',
        },
      ];
      setRooms(mockRooms);
    };

    // Uncomment below for real backend calls
    // const fetchRooms = async () => {
    //   try {
    //     const response = await fetch('/api/release-rooms'); // Update to match your backend endpoint
    //     if (!response.ok) {
    //       throw new Error('Failed to fetch rooms');
    //     }
    //     const data = await response.json();
    //     setRooms(data);
    //   } catch (error) {
    //     console.error('Error fetching rooms:', error);
    //   }
    // };

    // Replace fetchRooms with fetchMockRooms for testing
    fetchMockRooms();
  }, []);

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
    navigate(`/release-readiness-tracker/${room.roomId}`); // Navigate to specific room's dashboard
  };

  const handleRoomCreate = (newRoom) => {
    setRooms([newRoom, ...rooms]);
    setShowCreateRoomModal(false);
  };

  return (
    <div className="release-readiness-tracker-page">
      <div className="header">
        <h1>Release Readiness Tracker</h1>
        <button
          className="create-room-button"
          onClick={() => setShowCreateRoomModal(true)}
        >
          + Create New Room
        </button>
      </div>

      <div className="room-history">
        <h2>Room History</h2>
        {rooms.length > 0 ? (
          <div className="room-list">
            {rooms.map((room) => (
              <div
                key={room.roomId}
                className={`room-item ${
                  selectedRoom && selectedRoom.roomId === room.roomId ? 'active' : ''
                }`}
                onClick={() => handleRoomSelect(room)}
              >
                <div className="room-info">
                  <p><strong>{room.name}</strong></p>
                  <p>{new Date(room.targetReleaseDate).toLocaleDateString()}</p>
                </div>
                <div
                  className={`room-status ${
                    room.readiness === 'Ready' ? 'ready' : 'not-ready'
                  }`}
                >
                  {room.readiness}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-rooms-message">No rooms available. Create a new one to get started!</p>
        )}
      </div>

      {showCreateRoomModal && (
        <CreateRoomModal
          onClose={() => setShowCreateRoomModal(false)}
          onRoomCreate={handleRoomCreate}
        />
      )}

      {selectedRoom && <RoomDashboard room={selectedRoom} />}
    </div>
  );
};

export default ReleaseReadinessTrackerPage;
