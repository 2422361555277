import React from 'react';

function About() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#ffffff]">
      <div className="w-full max-w-4xl p-6">
        <h1 className="text-4xl font-bold mb-6 text-[#ffd600] text-center">About Us</h1>
        
        <div className="flex flex-col md:flex-row items-center mb-6">
          {/* Image Section */}
          <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-6">
            <img
              src="/Assets/Myphoto.jpeg" // Replace with the actual path to your image
              alt="Creator's Photo"
              className="rounded-full w-40 h-40 md:w-48 md:h-48 object-cover border-4 border-[#ffd600]"
            />
          </div>

          {/* Content Section */}
          <div className="text-center md:text-left">
            <p className="mb-4 text-lg">
            <strong>Hey I am Karthik.</strong> I’m an accomplished professional with over <strong>10+ years</strong> of experience in delivering high-impact projects and building innovative solutions for businesses worldwide. My expertise lies in leading <strong>large-scale projects</strong> using <strong>Scrum Agile methodologies</strong>, focusing on continuous improvement and client satisfaction.
            </p>
            <p className="mb-4 text-lg">
              Throughout my career, I’ve successfully implemented project methodologies such as <strong>Agile Techniques</strong>, including <strong>User Stories</strong>, <strong>Continuous Integration</strong>, <strong>TDD</strong>, and <strong>Automated Testing</strong>. My passion for driving efficiency and embracing innovation has helped sustain and grow businesses through lean engineering practices and seamless project execution.
            </p>
          </div>
        </div>

        <h2 className="text-2xl font-semibold mb-4 text-[#ffd600]">Why AgileHubTools?</h2>
        <p className="mb-4 text-lg">
          The idea of designing <strong>AgileHubTools</strong> stems from my extensive experience in project management. While working on diverse projects, I identified the need for a <strong>comprehensive and user-friendly platform</strong> to:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>Streamline Agile Processes: Tools for Scrum ceremonies, sprint planning, and retrospectives.</li>
          <li>Enhance Team Collaboration: Enable efficient communication and progress tracking across remote teams.</li>
          <li>Deliver Actionable Insights: Provide metrics and feedback to improve team performance.</li>
          <li>Simplify Workflow Management: Reduce the complexity of managing multiple projects simultaneously.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-[#ffd600]">Our Mission</h2>
        <p className="text-lg">
          At <strong>AgileHubTools</strong>, our mission is to build a tool that simplifies Agile practices, fosters innovation, and transforms the way teams work. Whether you are a project lead, a Scrum Master, or a team member, this platform is tailored to meet your needs and help you deliver exceptional results.
        </p>
      </div>
    </div>
  );
}

export default About;
