import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import './ScenarioAnalysis.css';

const ScenarioAnalysis = () => {
  const [scenarios, setScenarios] = useState([
    { label: 'Current', resolvedBlockers: 5, passedTests: 20, readiness: 65 },
  ]);
  const [newScenario, setNewScenario] = useState({
    resolvedBlockers: 0,
    passedTests: 0,
  });

  const calculateReadiness = (resolvedBlockers, passedTests) => {
    const readiness = Math.min(100, resolvedBlockers * 5 + passedTests * 2); // Simplified logic
    return readiness;
  };

  const handleAddScenario = () => {
    const newReadiness = calculateReadiness(
      newScenario.resolvedBlockers,
      newScenario.passedTests
    );

    setScenarios((prev) => [
      ...prev,
      {
        label: `Scenario ${prev.length + 1}`,
        resolvedBlockers: newScenario.resolvedBlockers,
        passedTests: newScenario.passedTests,
        readiness: newReadiness,
      },
    ]);
    setNewScenario({ resolvedBlockers: 0, passedTests: 0 });
  };

  const chartData = {
    labels: scenarios.map((scenario) => scenario.label),
    datasets: [
      {
        label: 'Readiness (%)',
        data: scenarios.map((scenario) => scenario.readiness),
        backgroundColor: 'rgba(75, 192, 192, 0.4)',
        borderColor: 'rgba(75, 192, 192, 1)',
        fill: true,
      },
    ],
  };

  return (
    <div className="scenario-analysis">
      <h2>Scenario Analysis</h2>
      <div className="scenario-inputs">
        <input
          type="number"
          placeholder="Resolved Blockers"
          value={newScenario.resolvedBlockers}
          onChange={(e) =>
            setNewScenario({ ...newScenario, resolvedBlockers: +e.target.value })
          }
        />
        <input
          type="number"
          placeholder="Passed Tests"
          value={newScenario.passedTests}
          onChange={(e) =>
            setNewScenario({ ...newScenario, passedTests: +e.target.value })
          }
        />
        <button onClick={handleAddScenario}>Add Scenario</button>
      </div>
      <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
    </div>
  );
};

export default ScenarioAnalysis;
