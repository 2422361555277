import { SignInButton, SignUpButton, SignOutButton, useAuth } from '@clerk/clerk-react';
import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const { isSignedIn } = useAuth();

  return (
    <nav
      className="fixed top-0 left-64 right-0 z-40 flex items-center justify-between p-4 shadow-md"
      style={{ backgroundColor: '#121212', color: '#ffffff' }} // Dark Gray Background and White Text
    >
      {/* Navigation Links */}
      <div className="flex space-x-8">
        <Link to="/" className="text-[#ffffff] hover:text-[#ffd600]" aria-label="Home">
          Home
        </Link>
        <Link to="/about" className="text-[#ffffff] hover:text-[#ffd600]" aria-label="About Us">
          About Us
        </Link> {/* Added About Us link */}
        <Link to="/features" className="text-[#ffffff] hover:text-[#ffd600]" aria-label="Features">
          Features
        </Link>
        <Link to="/contact" className="text-[#ffffff] hover:text-[#ffd600]" aria-label="Contact">
          Contact
        </Link>
      </div>

      {/* Authentication Buttons */}
      <div className="flex space-x-4">
        {isSignedIn ? (
          <SignOutButton>
            <button
              className="bg-[#00bcd4] text-[#121212] px-4 py-2 rounded hover:bg-[#e91e63]"
              aria-label="Sign Out"
            >
              Sign Out
            </button>
          </SignOutButton>
        ) : (
          <>
            <SignInButton mode="modal">
              <button
                className="bg-[#00bcd4] text-[#121212] px-4 py-2 rounded hover:bg-[#e91e63]"
                aria-label="Sign In"
              >
                Sign In
              </button>
            </SignInButton>
            <SignUpButton mode="modal">
              <button
                className="bg-[#00bcd4] text-[#121212] px-4 py-2 rounded hover:bg-[#e91e63]"
                aria-label="Sign Up"
              >
                Sign Up
              </button>
            </SignUpButton>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
