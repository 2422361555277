import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import trackEvent from '../utils/analytics'; // Import the analytics utility

function Sidebar() {
  const [isHovered, setIsHovered] = useState(false);

  // Function to track menu item clicks
  const handleMenuItemClick = (menuName) => {
    trackEvent('menu_item_click', {
      menu_name: menuName,
    });
  };

  // Function to track hover state
  const handleHover = () => {
    trackEvent('sidebar_hover', {
      event: 'hover',
      sidebar_state: isHovered ? 'closing' : 'opening',
    });
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen z-40 flex"
      onMouseEnter={() => {
        setIsHovered(true);
        handleHover();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        handleHover();
      }}
    >
      <div
        className="fixed top-0 left-0 h-full w-4 z-40"
        onMouseEnter={() => {
          setIsHovered(true);
          handleHover();
        }}
      ></div>

      <div
        className="fixed top-4 left-4 flex items-center space-x-3"
        style={{ zIndex: 50, color: '#0FFCBE' }}
      >
        <img src="/Assets/logo.webp" className="h-8" alt="AgileHubTools Logo" />
        <span className="text-2xl font-semibold">AgileHubTools</span>
      </div>

      <aside
        className={`transition-transform duration-300 transform ${
          isHovered ? 'translate-x-0' : '-translate-x-full'
        }`}
        style={{
          backgroundColor: '#121212',
          color: '#0FFCBE',
          width: '250px',
        }}
      >
        <div className="h-full px-3 py-4 overflow-y-auto mt-16">
          <ul className="space-y-2 font-medium">
            <li>
              <NavLink
                to="/"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200"
                style={{ color: '#0FFCBE' }}
                onClick={() => handleMenuItemClick('Home')}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/planit-poker"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200"
                style={{ color: '#0FFCBE' }}
                onClick={() => handleMenuItemClick('Planit Poker')}
              >
                Planit Poker
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/retro"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200"
                style={{ color: '#0FFCBE' }}
                onClick={() => handleMenuItemClick('Retro')}
              >
                Retro
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/release-readiness-tracker"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200"
                style={{ color: '#0FFCBE' }}
                onClick={() => handleMenuItemClick('Release Readiness Tracker')}
              >
                Release Readiness Tracker
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
