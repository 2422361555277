import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth, SignIn } from '@clerk/clerk-react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About'; // About Us page
import PlanitPoker from './pages/PlanitPoker';
import PlanItPokerRoom from './pages/PlanItPokerRoom';
import Retro from './pages/Retro';
import RoomView from './pages/RoomView';
import RoomHistory from './pages/RoomHistory';
import Features from './pages/Features';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import ReleaseReadinessTrackerPage from './pages/ReleaseReadinessTrackerPage';
import RoomDashboard from './components/RoomDashboard';
import './tailwind.css';
import usePageTracking from './hooks/usePageTracking';
import './App.css';

function ProtectedRoute({ children }) {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return isSignedIn ? children : <Navigate to="/sign-in" replace />;
}

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const path = window.location.pathname;
    let title = 'AgileHubTools';
    if (path.includes('planit-poker')) title += ' - PlanIt Poker';
    if (path.includes('retro')) title += ' - Retro';
    if (path.includes('features')) title += ' - Features';
    if (path.includes('contact')) title += ' - Contact';
    if (path.includes('about')) title += ' - About Us'; // Update for About Us
    if (path.includes('release-readiness-tracker')) title += ' - Release Readiness Tracker';
    document.title = title;
  }, [window.location.pathname]);

  return (
    <Router>
      {/* Call usePageTracking inside the Router */}
      <PageWrapper>
        <Header toggleSidebar={toggleSidebar} />
        <div className="flex flex-1 pt-16">
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <div className="flex-1 p-4 sm:ml-64 flex flex-col">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} /> {/* About Us route */}
              <Route path="/features" element={<Features />} />
              <Route path="/contact" element={<Contact />} />
              <Route
                path="/release-readiness-tracker"
                element={<ReleaseReadinessTrackerPage />}
              />
              <Route
                path="/release-readiness-tracker/:roomId"
                element={<RoomDashboard />}
              />
              <Route
                path="/planit-poker"
                element={
                  <ProtectedRoute>
                    <PlanitPoker />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/retro"
                element={
                  <ProtectedRoute>
                    <Retro />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/planit-poker-room/:roomId"
                element={
                  <ProtectedRoute>
                    <PlanItPokerRoom />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/room/:id"
                element={
                  <ProtectedRoute>
                    <RoomView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/roomhistory/:id"
                element={
                  <ProtectedRoute>
                    <RoomHistory />
                  </ProtectedRoute>
                }
              />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
        <Footer /> {/* Footer added */}
      </PageWrapper>
    </Router>
  );
}

// New wrapper component for calling `usePageTracking`
function PageWrapper({ children }) {
  usePageTracking();
  return <>{children}</>;
}

export default App;
