import React, { useRef, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './RoomDashboard.css';
import ScenarioAnalysis from './ScenarioAnalysis';
import CustomChecklist from './CustomChecklist';
import PostReleaseMetrics from './PostReleaseMetrics';
import TimelineView from './TimelineView';
import RiskAssessment from './RiskAssessment'; // Import RiskAssessment

const RoomDashboard = ({ room }) => {
  const dashboardRef = useRef();
  const [loading, setLoading] = useState(true);
  const [offlineMode, setOfflineMode] = useState(false);

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const downloadSnapshot = async () => {
    const canvas = await html2canvas(dashboardRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('portrait', 'mm', 'a4');
    pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
    pdf.save(`${room?.name || 'Room'}_DashboardSnapshot.pdf`);

    if (offlineMode && room?.name) {
      localStorage.setItem(`${room.name}_Snapshot`, imgData);
    }
  };

  const loadOfflineSnapshot = () => {
    if (!room?.name) {
      alert('No room data available.');
      return;
    }

    const snapshot = localStorage.getItem(`${room.name}_Snapshot`);
    if (snapshot) {
      const win = window.open();
      win.document.write(`<img src="${snapshot}" alt="Offline Snapshot"/>`);
      win.document.close();
    } else {
      alert('No offline snapshot available.');
    }
  };

  if (loading) {
    return <div className="spinner"></div>;
  }

  if (!room) {
    return <div className="error-message">No room data available.</div>;
  }

  return (
    <div className="room-dashboard">
      <h2>Dashboard for {room.name || 'Unknown Room'}</h2>

      <div className="offline-toggle">
        <label>
          <input
            type="checkbox"
            checked={offlineMode}
            onChange={() => setOfflineMode(!offlineMode)}
          />
          Enable Offline Mode
        </label>
      </div>

      <div ref={dashboardRef} className="dashboard-content">
        <Bar
          data={{
            labels: ['Active Blockers', 'Resolved Blockers', 'Passed', 'Failed', 'Pending'],
            datasets: [
              {
                label: 'Counts',
                data: [3, 5, 20, 10, 7], // Example data
                backgroundColor: [
                  'rgba(255, 99, 132, 0.6)',
                  'rgba(75, 192, 192, 0.6)',
                  'rgba(54, 162, 235, 0.6)',
                  'rgba(255, 206, 86, 0.6)',
                  'rgba(153, 102, 255, 0.6)',
                ],
              },
            ],
          }}
          options={{ responsive: true, maintainAspectRatio: false }}
        />

        <CustomChecklist roomId={room.id} />
        <ScenarioAnalysis roomId={room.id} />
        <PostReleaseMetrics roomId={room.id} />
        <TimelineView roomId={room.id} />
        <RiskAssessment roomId={room.id} /> {/* Integrated Risk Assessment */}
      </div>

      <button className="snapshot-button" onClick={downloadSnapshot}>
        Download Snapshot
      </button>
      {offlineMode && (
        <button className="offline-view-button" onClick={loadOfflineSnapshot}>
          View Offline Snapshot
        </button>
      )}
    </div>
  );
};

export default RoomDashboard;
