import React, { useState, useEffect } from 'react';
import './RiskAssessment.css';

const RiskAssessment = ({ roomId }) => {
  const [risks, setRisks] = useState([]);
  const [newRisk, setNewRisk] = useState({ title: '', priority: 'High', mitigation: '', status: 'Open' });
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortPriority, setSortPriority] = useState('High');

  useEffect(() => {
    // Mock data for risks
    const mockRisks = [
      { id: 1, title: 'Delayed deployment', priority: 'High', mitigation: 'Increase team support', status: 'Open' },
      { id: 2, title: 'Insufficient testing', priority: 'Medium', mitigation: 'Add test cycles', status: 'In Progress' },
      { id: 3, title: 'Resource availability', priority: 'Low', mitigation: 'Allocate backup resources', status: 'Resolved' },
    ];
    setRisks(mockRisks);
  }, [roomId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRisk((prev) => ({ ...prev, [name]: value }));
  };

  const addRisk = () => {
    setRisks((prev) => [
      ...prev,
      { ...newRisk, id: prev.length + 1 },
    ]);
    setNewRisk({ title: '', priority: 'High', mitigation: '', status: 'Open' });
  };

  const updateStatus = (id, status) => {
    setRisks((prev) =>
      prev.map((risk) => (risk.id === id ? { ...risk, status } : risk))
    );
  };

  const filteredRisks = risks
    .filter((risk) => filterStatus === 'All' || risk.status === filterStatus)
    .sort((a, b) => (sortPriority === 'High' ? b.priority.localeCompare(a.priority) : a.priority.localeCompare(b.priority)));

  return (
    <div className="risk-assessment">
      <h2>Risk Assessment for Room {roomId}</h2>

      {/* Risk Filters */}
      <div className="filters">
        <label>
          Filter by Status:
          <select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
            <option value="All">All</option>
            <option value="Open">Open</option>
            <option value="In Progress">In Progress</option>
            <option value="Resolved">Resolved</option>
          </select>
        </label>

        <label>
          Sort by Priority:
          <select value={sortPriority} onChange={(e) => setSortPriority(e.target.value)}>
            <option value="High">High to Low</option>
            <option value="Low">Low to High</option>
          </select>
        </label>
      </div>

      {/* Add New Risk */}
      <div className="add-risk">
        <input
          type="text"
          name="title"
          placeholder="Risk Title"
          value={newRisk.title}
          onChange={handleInputChange}
        />
        <select
          name="priority"
          value={newRisk.priority}
          onChange={handleInputChange}
        >
          <option value="High">High</option>
          <option value="Medium">Medium</option>
          <option value="Low">Low</option>
        </select>
        <input
          type="text"
          name="mitigation"
          placeholder="Mitigation Steps"
          value={newRisk.mitigation}
          onChange={handleInputChange}
        />
        <button onClick={addRisk}>Add Risk</button>
      </div>

      <h3>Current Risks</h3>
      <ul className="risk-list">
        {filteredRisks.map((risk) => (
          <li key={risk.id} className={`risk-item ${risk.priority.toLowerCase()}`}>
            <strong>{risk.title}</strong> - {risk.priority}
            <p>Mitigation: {risk.mitigation}</p>
            <p>Status: 
              <select
                value={risk.status}
                onChange={(e) => updateStatus(risk.id, e.target.value)}
              >
                <option value="Open">Open</option>
                <option value="In Progress">In Progress</option>
                <option value="Resolved">Resolved</option>
              </select>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RiskAssessment;
