import React, { useState, useEffect } from 'react';
import { Sun, Moon, Cloud, CloudRain, CloudSnow, CloudLightning, Home } from 'lucide-react';
import './NotFound.css';

const NotFound = () => {
  const [weather, setWeather] = useState('cloudy');
  const [timeOfDay, setTimeOfDay] = useState('day');
  const [particles, setParticles] = useState([]);

  // Generate weather particles (rain or snow)
  useEffect(() => {
    if (weather === 'rainy' || weather === 'snowy') {
      const newParticles = Array.from({ length: 40 }, (_, i) => ({
        id: i,
        left: Math.random() * 100,
        delay: Math.random() * 3,
        duration: Math.random() * 2 + 1,
        size: weather === 'snowy' ? Math.random() * 5 + 2 : 2,
      }));
      setParticles(newParticles);
    } else {
      setParticles([]);
    }
  }, [weather]);

  // Determine background gradient based on weather and time
  const getBgColor = () => {
    const timeColors = {
      day: {
        sunny: 'from-blue-400 to-blue-200',
        cloudy: 'from-gray-400 to-gray-300',
        rainy: 'from-gray-600 to-gray-500',
        snowy: 'from-gray-300 to-blue-100',
        stormy: 'from-gray-800 to-gray-700',
      },
      night: {
        sunny: 'from-indigo-900 to-blue-900',
        cloudy: 'from-gray-900 to-gray-800',
        rainy: 'from-gray-900 to-gray-800',
        snowy: 'from-gray-900 to-blue-900',
        stormy: 'from-gray-900 to-black',
      },
    };
    return timeColors[timeOfDay][weather];
  };

  return (
    <div className={`not-found-container bg-gradient-to-b ${getBgColor()}`}>
      {/* Toggle Day/Night */}
      <button
        onClick={() => setTimeOfDay((prev) => (prev === 'day' ? 'night' : 'day'))}
        className="time-toggle"
      >
        {timeOfDay === 'day' ? <Sun className="icon" /> : <Moon className="icon" />}
      </button>

      {/* Weather Particles */}
      {particles.map((particle) => (
        <div
          key={particle.id}
          className={weather === 'rainy' ? 'raindrop' : 'snowflake'}
          style={{
            left: `${particle.left}%`,
            animationDuration: `${particle.duration}s`,
            animationDelay: `${particle.delay}s`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
          }}
        />
      ))}

      {/* Main Content */}
      <div className="content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">
          Looks like this page is lost in the {weather}.
        </p>

        {/* Weather Controls */}
        <div className="weather-controls">
          {[
            { type: 'sunny', icon: Sun },
            { type: 'cloudy', icon: Cloud },
            { type: 'rainy', icon: CloudRain },
            { type: 'snowy', icon: CloudSnow },
            { type: 'stormy', icon: CloudLightning },
          ].map(({ type, icon: Icon }) => (
            <button
              key={type}
              onClick={() => setWeather(type)}
              className={`weather-button ${weather === type ? 'active' : ''}`}
            >
              <Icon className="icon" />
            </button>
          ))}
        </div>

        <a href="/" className="not-found-button">
          <Home className="icon" />
          Return to Clear Skies
        </a>
      </div>
    </div>
  );
};

export default NotFound;
