import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import './PostReleaseMetrics.css';

const PostReleaseMetrics = ({ roomId }) => {
  const [metricsData, setMetricsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false); // For live refresh indicator

  useEffect(() => {
    // Initial fetch and simulate real-time updates
    const fetchMetrics = async () => {
      try {
        const mockMetrics = {
          adoptionRate: [20, 40, 60, 75, 90], // Mock weekly adoption rate
          errorRate: [5, 3, 4, 2, 1], // Mock weekly error rate
          feedback: [10, 15, 20, 25, 30], // Mock feedback count
        };
        setMetricsData(mockMetrics);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch metrics:', error);
      }
    };

    fetchMetrics();

    // Simulate live updates every 10 seconds
    const interval = setInterval(() => {
      setIsUpdating(true);
      setTimeout(() => {
        setMetricsData((prev) => ({
          ...prev,
          adoptionRate: prev.adoptionRate.map((rate) => rate + Math.floor(Math.random() * 5)),
          errorRate: prev.errorRate.map((rate) => Math.max(rate - Math.random(), 0)),
          feedback: prev.feedback.map((count) => count + Math.floor(Math.random() * 5)),
        }));
        setIsUpdating(false);
      }, 1000); // Simulate data update delay
    }, 10000);

    return () => clearInterval(interval); // Cleanup
  }, [roomId]);

  if (loading) {
    return <div className="metrics-loading">Loading Post-Release Metrics...</div>;
  }

  const lineChartData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    datasets: [
      {
        label: 'Adoption Rate (%)',
        data: metricsData.adoptionRate,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4,
      },
      {
        label: 'Error Rate (%)',
        data: metricsData.errorRate,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        tension: 0.4,
      },
    ],
  };

  const barChartData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    datasets: [
      {
        label: 'Feedback Count',
        data: metricsData.feedback,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  return (
    <div className="post-release-metrics">
      <h2>Post-Release Metrics</h2>
      {isUpdating && <p className="updating-indicator">Updating metrics...</p>}

      <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
      <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
    </div>
  );
};

export default PostReleaseMetrics;
